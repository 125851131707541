// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-export-overview {
  display: block;
}

.gallery-thing-export-overview--NoReportsAvailable {
  text-align: center;
  font-weight: 400;
  font-size: 17px;
  margin: 32px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-export-overview/gallery-thing-export-overview.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["gallery-thing-export-overview {\n  display: block;\n}\n\n.gallery-thing-export-overview--NoReportsAvailable {\n  text-align: center;\n  font-weight: 400;\n  font-size: 17px;\n  margin: 32px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
