// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `gallery-thing-picture-export-panel {
  display: block;
  background: var(--record-it-color-gray-300);
}

.gallery-thing-picture-export-panel--ParameterPanel {
  margin-bottom: 0;
}

.gallery-thing-picture-export-panel--ReportTypeSelectRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.gallery-thing-picture-export-panel--ReportTypeSelectLabel {
  font-size: 13px;
  width: 100%;
}

.gallery-thing-picture-export-panel--ReportTypeSelectRow > custom-select {
  flex: 1 1 auto;
  margin-right: 15px;
}

.gallery-thing-picture-export-panel--CurrentReportTypeText {
  margin-top: 5px;
}

.gallery-thing-picture-export-panel--ReportTypeSelectRow,
.gallery-thing-picture-export-panel--CurrentReportTypeText {
  margin-bottom: 10px;
}

.gallery-thing-picture-export-panel--CurrentReportTypeUpDownloadWrapper {
  margin-right: 10px;
  margin-top: 3px;
  flex: 0 0;
}

.gallery-thing-picture-export-panel--SetReportTypeButton {
  flex: 0 1 auto;
  margin-top: 3px;
}

.gallery-thing-picture-export-panel--ExportButtonsWrapper {
  text-align: right;
  margin-top: 25px;
}

.gallery-thing-picture-export-panel--SelectedPicturesText {
  display: inline;
  margin-right: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/galleryThing/gallery-thing-picture-export-panel/gallery-thing-picture-export-panel.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,2CAA2C;AAC7C;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,SAAS;AACX;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["gallery-thing-picture-export-panel {\n  display: block;\n  background: var(--record-it-color-gray-300);\n}\n\n.gallery-thing-picture-export-panel--ParameterPanel {\n  margin-bottom: 0;\n}\n\n.gallery-thing-picture-export-panel--ReportTypeSelectRow {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.gallery-thing-picture-export-panel--ReportTypeSelectLabel {\n  font-size: 13px;\n  width: 100%;\n}\n\n.gallery-thing-picture-export-panel--ReportTypeSelectRow > custom-select {\n  flex: 1 1 auto;\n  margin-right: 15px;\n}\n\n.gallery-thing-picture-export-panel--CurrentReportTypeText {\n  margin-top: 5px;\n}\n\n.gallery-thing-picture-export-panel--ReportTypeSelectRow,\n.gallery-thing-picture-export-panel--CurrentReportTypeText {\n  margin-bottom: 10px;\n}\n\n.gallery-thing-picture-export-panel--CurrentReportTypeUpDownloadWrapper {\n  margin-right: 10px;\n  margin-top: 3px;\n  flex: 0 0;\n}\n\n.gallery-thing-picture-export-panel--SetReportTypeButton {\n  flex: 0 1 auto;\n  margin-top: 3px;\n}\n\n.gallery-thing-picture-export-panel--ExportButtonsWrapper {\n  text-align: right;\n  margin-top: 25px;\n}\n\n.gallery-thing-picture-export-panel--SelectedPicturesText {\n  display: inline;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
