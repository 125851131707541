// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ultra-rapid-fire-widget--TakePictureButton {
  width: 72px;
  height: 72px;
  background: var(--record-it-color-gray-300);
  border: 7px solid white;
  border-radius: 50%;
}

.ultra-rapid-fire-widget--TakePictureButton:hover {
  background: var(--record-it-color-gray-600);
  border-color: var(--record-it-color-gray-300);
}

.ultra-rapid-fire-widget--TakePictureButton:active {
  background: var(--record-it-color-gray-700);
  border-color: var(--record-it-color-gray-400);
}

.ultra-rapid-fire-widget--TakePictureButtonAbsPosition {
  position: absolute;
  right: 44px;
  top: 50%;
  transform: translateY(-50%);
}

@media (orientation: portrait) {
  .ultra-rapid-fire-widget--TakePictureButtonAbsPosition {
    top: unset;
    right: unset;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/ultra-rapid-fire-widget/urfw-take-picture-button/urfw-take-picture-button.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;EAC3C,6CAA6C;AAC/C;;AAEA;EACE,2CAA2C;EAC3C,6CAA6C;AAC/C;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE;IACE,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,2BAA2B;EAC7B;AACF","sourcesContent":[".ultra-rapid-fire-widget--TakePictureButton {\n  width: 72px;\n  height: 72px;\n  background: var(--record-it-color-gray-300);\n  border: 7px solid white;\n  border-radius: 50%;\n}\n\n.ultra-rapid-fire-widget--TakePictureButton:hover {\n  background: var(--record-it-color-gray-600);\n  border-color: var(--record-it-color-gray-300);\n}\n\n.ultra-rapid-fire-widget--TakePictureButton:active {\n  background: var(--record-it-color-gray-700);\n  border-color: var(--record-it-color-gray-400);\n}\n\n.ultra-rapid-fire-widget--TakePictureButtonAbsPosition {\n  position: absolute;\n  right: 44px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n@media (orientation: portrait) {\n  .ultra-rapid-fire-widget--TakePictureButtonAbsPosition {\n    top: unset;\n    right: unset;\n    bottom: 25px;\n    left: 50%;\n    transform: translateX(-50%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
