// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `checklist_inspection_question {
  display: block;
}

.checklist-inspection-question--DividedVertical > * + * {
  border-top: 1px solid var(--record-it-color-gray-300);
}

.checklist-inspection-category--SpaceBetween > * + * {
  margin-top: 1.6rem;
}

.checklist-inspection-question--Item {
  padding: 1.8rem 1.4rem;
}

.checklist-inspection-question--QuestionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checklist-inspection-question--QuestionHeaderTitle {
  font-size: 1.2rem;
  margin: 0;
}

.checklist-inspection-question--LinkToCategoryIcon {
  font-size: 24px;
  color: var(--record-it-color-gray-600);
}

.checklist-inspection-question--QuestionTitle {
  font-size: 1.6rem;
  margin: 0;
}

.checklist-inspection-category--QuestionChoices {
  width: 100%;
}

.selection-switch--ButtonsWrapper {
  column-gap: 2.4em;
  row-gap: 1.2em;
}

.checklist-inspection-question--TextHeader {
  display: flex;
  align-items: baseline;
}

.checklist-inspection-question--FullWidth {
  width: 100%;
}

.checklist-inspection-question--Pictures {
  display: flex;
  gap: 1.2rem;
  flex-wrap: wrap;
}

.checklist-inspection-question--Picture {
  height: 140px;
  width: 140px;
  aspect-ratio: 1;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/pages/checklist_inspection_question/checklist_inspection_question.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":["checklist_inspection_question {\n  display: block;\n}\n\n.checklist-inspection-question--DividedVertical > * + * {\n  border-top: 1px solid var(--record-it-color-gray-300);\n}\n\n.checklist-inspection-category--SpaceBetween > * + * {\n  margin-top: 1.6rem;\n}\n\n.checklist-inspection-question--Item {\n  padding: 1.8rem 1.4rem;\n}\n\n.checklist-inspection-question--QuestionHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.checklist-inspection-question--QuestionHeaderTitle {\n  font-size: 1.2rem;\n  margin: 0;\n}\n\n.checklist-inspection-question--LinkToCategoryIcon {\n  font-size: 24px;\n  color: var(--record-it-color-gray-600);\n}\n\n.checklist-inspection-question--QuestionTitle {\n  font-size: 1.6rem;\n  margin: 0;\n}\n\n.checklist-inspection-category--QuestionChoices {\n  width: 100%;\n}\n\n.selection-switch--ButtonsWrapper {\n  column-gap: 2.4em;\n  row-gap: 1.2em;\n}\n\n.checklist-inspection-question--TextHeader {\n  display: flex;\n  align-items: baseline;\n}\n\n.checklist-inspection-question--FullWidth {\n  width: 100%;\n}\n\n.checklist-inspection-question--Pictures {\n  display: flex;\n  gap: 1.2rem;\n  flex-wrap: wrap;\n}\n\n.checklist-inspection-question--Picture {\n  height: 140px;\n  width: 140px;\n  aspect-ratio: 1;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
